import React from 'react'
import Email from './Details/Email'
import Telephone from './Details/Telephone'

export default function SplashScreen() {
  return (
    <div className="wrapper" style={{
      backgroundImage: 'linear-gradient(rgba(00, 44, 88, 0.54), rgba(88, 44, 00, 0.87)), url(/IMG_6614.JPG)'
    }}>
      <div className="content">
        <img alt="DK" src='/dklogo.png' className="logo" />
        <h1>Coming Soon</h1>
        <div className="info">Check back when the site goes live!</div>
        <div class="contact"><Email /><Telephone /></div>
      </div>
    </div>
  )
}
