import React from 'react'

export default function Flex({ children, style }) {
	return <div style={{ display: 'flex', ...style }}>{children}</div>
}

export function FlexItem({ children, style }) {
	return <div style={{ flex: 1, ...style }}>{children}</div>
}

export function Row({ children, style }) {
	return <div style={{ display: 'flex', flexDirection: 'row', ...style }}>{children}</div>
}

export function Col({ children, style }) {
	return <div style={{ display: 'flex', flexDirection: 'column', ...style }}>{children}</div>
}
