import React from 'react'
import { Row } from '../Flexbox/Flexbox'
import Anchor from '../Anchor/Anchor'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
export default function Telephone({ style }) {
	return (
		<Row style={{ gap: '10px', alignItems: 'center' }}>
			<Anchor href='tel:+447394483646' style={{ fontSize: '16px', textDecoration: 'none', ...style }}>
				<LocalPhoneIcon />
				+44 (0) 7394483646
			</Anchor>
		</Row>
	)
}
