import React from 'react'
import EmailIcon from '@mui/icons-material/Email'
import { Row } from '../Flexbox/Flexbox'
import Anchor from '../Anchor/Anchor'

export default function Email({ style }) {
	return (
		<Row style={{ gap: '10px', alignItems: 'center' }}>
			<Anchor href='mailto:info@dkspotlesscleaning.com' style={{ fontSize: '16px', textDecoration: 'none', ...style }}>
				<EmailIcon />
				info@dkspotlesscleaning.co.uk
			</Anchor>
		</Row>
	)
}
