import { Button } from '@mui/material'
import React from 'react'

export default function Anchor({ children, href, style }) {
	return (
		<Button style={{ color: '#c7c7c7', display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px', textTransform: 'none', ...style }} href={href}>
			{children}
		</Button>
	)
}
